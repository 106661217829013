<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <vs-popup title="Détail commande" :active.sync="activePromptDetail">
        <div class="vx-col w-full">
          <span class="font-black">Officine:</span> {{commandeVerre ? getOfficineById(commandeVerre.IdOfficine).NomOffic  : '' }}
        </div>
        <div class="vx-col w-full">
          <span class="font-black">Date commande:</span> {{commandeVerre ? new Date(commandeVerre.dateCommande).toISOString().split('T')[0] : '' }}
        </div>
        <div class="vx-col w-full">
          <span class="font-black">Nom:</span> {{commandeVerre ? commandeVerre.NomClient : ''}}
        </div>
        <div class="vx-col w-full">
          <span class="font-black">Prénoms:</span> {{commandeVerre ? commandeVerre.PrenomsClient : ''}}
        </div>
        <div class="vx-col w-full">
          <span class="font-black">Fournisseur:</span> {{commandeVerre ? showFournisseurName(commandeVerre.fournVerreId) : ''}}
        </div>
        <div class="vx-col w-full">
          <span class="font-black">Type de verre:</span> {{commandeVerre ? commandeVerre.typeVerre : ''}}
        </div>
        <div class="vx-col w-full">
          <span class="font-black">Verre droit:</span> {{commandeVerre ? commandeVerre.libelleVerreD : ''}}
        </div>
        <div class="vx-col w-full">
          <span class="font-black">Verre gauche:</span> {{commandeVerre ? commandeVerre.libelleVerreG : ''}}
        </div>
        <div class="vx-col w-full">
          <div class="vx-row">
            <div class="vx-col">
              <span class="font-black">Livré:</span>
            </div>
            <div class="vx-col">
            <template v-if="commandeVerre">
              <template v-if="commandeVerre.livre">
                <vs-chip transparent color="primary">
                  Oui
                </vs-chip>
              </template>
              <template v-else>
                <vs-chip transparent color="danger">
                  Non
                </vs-chip>
              </template>
            </template>
            </div>
          </div>
        </div>
        <div class="vx-col w-full">
          <span class="font-black">Date de livraison:</span> {{commandeVerre ? (commandeVerre.dateLivraison ? new Date(commandeVerre.dateLivraison).toISOString().split('T')[0] : '') : ''}}
        </div>
        <div class="vx-col w-full">
          <div class="vx-row">
            <div class="vx-col">
              <span class="font-black">Retour:</span>
            </div>
            <div class="vx-col">
            <template v-if="commandeVerre">
              <template v-if="commandeVerre.retourVerre">
                <vs-chip transparent color="danger">
                  Oui
                </vs-chip>
              </template>
              <template v-else>
                <vs-chip transparent color="primary">
                  Non
                </vs-chip>
              </template>
            </template>
            </div>
          </div>
        </div>
        <div class="vx-col w-full">
          <span class="font-black">Date de retour:</span> {{commandeVerre ? (commandeVerre.dateRetour ? new Date(commandeVerre.dateRetour).toISOString().split('T')[0] : '') : ''}}
        </div>
        <div class="vx-col w-full">
          <span class="font-black">Motif:</span> {{commandeVerre ? commandeVerre.motifRetour : ''}}
        </div>
        <div class="vx-col w-full">
          <div class="vx-row">
            <div class="vx-col">
              <span class="font-black">Casse:</span>
            </div>
            <div class="vx-col">
            <template v-if="commandeVerre">
              <template v-if="commandeVerre.casse">
                <vs-chip transparent color="danger">
                  Oui
                </vs-chip>
              </template>
              <template v-else>
                <vs-chip transparent color="primary">
                  Non
                </vs-chip>
              </template>
            </template>
            </div>
          </div>
        </div>
        <div class="vx-col w-full">
          <span class="font-black">Date de casse:</span> {{commandeVerre ? (commandeVerre.dateCasse ? new Date(commandeVerre.dateCasse).toISOString().split('T')[0] : '') : ''}}
        </div>
      </vs-popup>
      <vx-card>
        <vs-table noDataText="Aucune donnée" v-model="selected" stripe pagination max-items="50" search :data="filterCommandesVerres(statusFiltre)">
          <template slot="header">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div class="p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium w-32">
                <span class="mr-2 leading-none">Actions</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>

              <vs-dropdown-menu>

                <vs-dropdown-item>
                  <span class="flex items-center">
                    <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                    <span>Supprimer</span>
                  </span>
                </vs-dropdown-item>

                <vs-dropdown-item>
                  <span class="flex items-center">
                    <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                    <span>CSV</span>
                  </span>
                </vs-dropdown-item>

              </vs-dropdown-menu>
            </vs-dropdown>
            <template v-if="selectUserOfficine(activeUserInfos.IdOfficine) && selectUserOfficine(activeUserInfos.IdOfficine).OfficinePrincipale === true">
              <vs-select placeholder="Officine" autocomplete class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4 ml-4" v-model="idOfficine" @change="getCommandesVerres(idOfficine)">
                <vs-select-item  class="w-full" :key="index" :value="item._id" :text="item.NomOffic" v-for="(item,index) in officines" />
              </vs-select>
            </template>
            <template>
              <vs-select placeholder="Status" autocomplete class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4 ml-4" v-model="statusFiltre" @change="filterCommandesVerres(statusFiltre)">
                <vs-select-item  class="w-full" :key="index" :value="item.valeur" :text="item.status" v-for="(item,index) in [{status: 'Touts', valeur: 'all'},{status: 'Livrée', valeur: true},{status: 'Non livrée', valeur: false}]" />
              </vs-select>
            </template>
          </template>
          <template slot="thead">
            <vs-th>
              N°
            </vs-th>
            <vs-th sort-key="dateCommande">
              Date cmde
            </vs-th>
            <vs-th sort-key="NumVente">
              N°vente
            </vs-th>
            <vs-th sort-key="fournVerreId">
              Four. Verre
            </vs-th>
            <vs-th>
              Oeil
            </vs-th>
            <vs-th>
              Verre / Sup
            </vs-th>
            <vs-th>
              Sph
            </vs-th>
            <vs-th>
              Cyl
            </vs-th>
            <vs-th>
              Axe
            </vs-th>
            <vs-th>
              Add
            </vs-th>
            <vs-th sort-key="NomClient">
              Client
            </vs-th>

            <vs-th sort-key="typeVerre">
              Type de verre
            </vs-th>
            <vs-th>
              Status
            </vs-th>
            <vs-th>Actions</vs-th>
          </template>
          <template slot-scope="{data}">
            <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  {{indextr + 1}}
                </vs-td>
                <vs-td>
                  {{tr.dateCommande | moment("calendar", "July 10 2011") }}
                </vs-td>
                <vs-td>
                  {{tr.NumVente}}
                </vs-td>
                <vs-td>
                  {{showFournisseurName(tr.fournVerreId)}}
                </vs-td>
                <vs-td>
                  D <br> G
                </vs-td>
                <vs-td>
                  {{tr.libelleVerreD}} <br> {{tr.libelleVerreG}}
                </vs-td>
                <vs-td>
                  {{tr.SphVD ? tr.SphVD : ''}} <br> {{tr.SphVG ? tr.SphVG : ''}}
                </vs-td>
                <vs-td>
                  {{tr.CylVD ? tr.CylVD : ''}} <br> {{tr.CylVG ? tr.CylVG : ''}}
                </vs-td>
                <vs-td>
                  {{tr.AxeVD ? tr.AxeVD : ''}} <br> {{tr.AxeVG ? tr.AxeVG : ''}}
                </vs-td>
                <vs-td>
                  {{tr.AddVD ? tr.AddVD : ''}} <br> {{tr.AddVG ? tr.AddVG : ''}}
                </vs-td>
                <vs-td>
                  {{tr.NomClient}} <br> {{tr.PrenomsClient}}
                </vs-td>
                <vs-td>
                  {{tr.typeVerre}}
                </vs-td>
                <vs-td>
                  <template v-if="tr.livre">
                    <vs-chip transparent color="primary">
                      Livrée
                    </vs-chip>
                  </template>
                  <template v-else>
                    <vs-chip transparent color="danger">
                      Non livrée
                    </vs-chip>
                  </template>
                </vs-td>
                <vs-td>
                  <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
                    <div class="dropdown-button-container">
                      <vs-dropdown>
                        <vs-button class="btn-drop" icon="more_vert"></vs-button>
                        <vs-dropdown-menu>
                          <vs-dropdown-item @click="showDetailsCommandeVerre(tr)">Détails</vs-dropdown-item>
                          <!-- <vs-dropdown-item>Modifier </vs-dropdown-item>
                          <vs-dropdown-item >Supprimer </vs-dropdown-item> -->
                        </vs-dropdown-menu>
                      </vs-dropdown>
                    </div>

                    <!-- <feather-icon title="Supprimer" icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord(tr._id, tr2.RefCmmd)" /> -->
                  </div>
                </vs-td>
              </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </vx-card>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      idOfficine: null,
      activeUserInfos: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {},
      selected: [],
      activePromptDetail: false,
      commandeVerre: null, 
      statusFiltre: 'all'
    }
  },
  components: {

  },
  computed: {
    commandes () {
      return this.$store.state.commande_verre.verresCommandes
    },
    officines () {
      return this.$store.state.officine.officines
    }
  },

  methods: {
    filterCommandesVerres (status) {
      return this.$store.getters['commande_verre/filterCommandesVerres'](status)
    },
    selectUserOfficine (id) {
      return this.$store.getters['officine/getOfficineById'](id)
    },
    showFournisseurName (id) {
      return id ? this.$store.getters['verre/showFournisseurName'](id) : ''
    },
    showDetailsCommandeVerre (data) {
      this.activePromptDetail = true
      this.commandeVerre = data
    },
    activeUserInfo () {
      const idOfficine = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).IdOfficine : null
      if (idOfficine) this.getCommandesVerres(idOfficine)
    },
    getCommandesVerres (id) {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('commande_verre/getCommandesVerres', id)
        .then(() => {
          this.$vs.loading.close()
        })
        .catch((err) => {
          this.$vs.loading.close()
          console.log('error commande verre:', err)
        })
    },
    getOfficines () {
      this.$store.dispatch('officine/getOfficines')
        .then(() => {

        })
        .catch((err) => {
          console.log(err)
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    getOfficineById (id) {
      return this.$store.getters['officine/getOfficineById'](id)
    },
    getFournisseursVerres () {
      this.$store.dispatch('verre/getFournisseursVerres')
        .catch((error) => { console.log(error) })
    }
  },
  created () {
    this.activeUserInfo()
    if (!(this.$store.state.verre.fournisseurs_verres.length > 0)) this.getFournisseursVerres()
    if (!(this.$store.state.officine.officines.length > 0)) this.getOfficines()
  }
}
</script>

